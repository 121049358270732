import React from 'react'
import classNames from 'classnames'

import { Layout } from '../components/Layout'
import { Container } from '../components/Container'

const links = [
  {
    url: 'https://www.advokatsamfundet.se/konsumenttvistnamnden',
    title: 'Advokatsamfundet.se - Konsumenttvistnämnden',
  },
  {
    url: 'https://www.domstol.se/',
    title: 'Domstol.se',
  },
  {
    url: 'https://skatteverket.se/',
    title: 'Skattverket.se',
  },
  {
    url: 'https://www.regeringen.se/',
    title: 'Regeringen.se',
  },
  {
    url: 'https://www.socialstyrelsen.se/',
    title: 'Socialstyrelsen.se',
  },
  {
    url: 'https://lagen.nu/',
    title: 'Lagen.nu',
  },
]

function InformationPage() {
  return (
    <Layout spaceTop={true}>
      <Container>
        <h1 className="font-serif text-3xl text-gray-700 pb-3">Länkar</h1>
        <hr />

        <div className="pt-6">
          <ul>
            {links.map((link, idx: number) => (
              <li key={idx} className={classNames({ 'mt-3': idx !== 0 })}>
                <a href={link.url} className="text-blue-600 hover:underline">
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </Layout>
  )
}

export default InformationPage
